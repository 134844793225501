import React, { ReactNode } from "react"

import { Script } from "gatsby"

import SiteContextProvider from "@context/site-context"

type wrapRootElementProps = {
   element: ReactNode
}

/**
 * Gatsby Script API aids in loading scripts performantly
 * See https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/ for more info.
 */

/**
 * Use script.local.js to test Plausible locally
 * https://plausible.io/docs/script-extensions
 *
 * Run proxy as a subdirector
 * https://plausible.io/docs/proxy/guides/cloudflare#step-6-optional-run-proxy-as-a-subdirectory
 */
export const wrapRootElement = ({ element }: wrapRootElementProps) => {
   return (
      <>
         <SiteContextProvider>{element}</SiteContextProvider>
         <Script data-domain="edbartholomew.com" data-api="/services/event" src="/services/script.js" />
      </>
   )
}
