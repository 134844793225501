import React, { ReactNode, createContext, useContext, useState } from "react"

export const SiteContext = createContext(null as any)

export const useSiteContext = () => useContext(SiteContext)

type SiteContextProviderProps = {
   children: ReactNode
}

export type LightBoxState = {
   toggler: boolean
   sources: string[]
   key: number
}

const SiteContextProvider = ({ children }: SiteContextProviderProps) => {
   const [lockScroll, setLockScroll] = useState(false)
   const [cvBodyClasses, setCvBodyClasses] = useState("")

   return (
      <SiteContext.Provider
         value={{
            lockScroll,
            setLockScroll,
            cvBodyClasses,
            setCvBodyClasses,
         }}
      >
         {children}
      </SiteContext.Provider>
   )
}

export default SiteContextProvider
